<template>
    <v-card
      class="card-shadow border-radius-xl"
      min-height="250"
  >
    <div class="px-6 pt-4 pb-0 d-flex">
      <v-avatar
          :color="`bg-gradient-${data.iconColor} border-radius-xl mt-n8`"
          class="shadow"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">
          {{ data.icon }}
        </v-icon>
      </v-avatar>
      <p class="font-weight-bold text-h4 mb-0 ms-3">
        {{ data.title }}
      </p>
    </div>
    <v-card-text>
      <hr>
      <v-data-table small :items="data.items" :headers="headers">
        <template v-slot:[`item.wine`]="{ item }">
          <div class="d-flex align-center ms-2 cursor-pointer truncate">
            <v-tooltip color="black" top lazy>
              <template #activator="{ on }">
                <!-- Aggiunto evento di click per aprire il dialog -->
                <span class="text-sm font-weight-bolder text-pink" v-on="on" @click="changePrice(item.id)">
                  {{ item.wine }}
                </span>
              </template>
              <span>Modifica il Prezzo</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-if="data.infoFooter">
      <hr>
    </v-card-text>
    <cambia-listino-prezzi :id-price-list-item="idPriceListino" @refresh="getRefresh"></cambia-listino-prezzi>
  </v-card>
</template>
<script>
import CambiaListinoPrezzi from "@/components/Custom/EditPriceListItemDashboard.vue";
export default {
  name: "StatsCardPriceListItem",
  props: {
    data: Object
  },
  components: {
    CambiaListinoPrezzi
  },
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Wine', value: 'wine'},
        {text: 'WineMaker', value: 'winemaker'},
        {text: 'Vintage', value: 'vintage'},
        {text: 'Prezzo di Vendita', value: 'price_sell'},
        {text: 'Last Price', value: 'last_price'},
        {text: 'Last Change', value: 'last_change_date'},
      ],
      idPriceListino: null,
    }
  },
  mounted() {
  },
  methods: {
    changePrice(id) {
      this.idPriceListino = id
      console.log(id)
    },
    getRefresh() {
      this.$emit('refresh')
    }
  }
}
</script>
<style scoped>
hr {
  color: #aaceec;
}
.list-item-border {
  border-bottom: 1px solid #e0e0e0; /* Scegli il colore del bordo che preferisci */
}
.v-list-item:nth-child(odd) {  /* Seleziona gli elementi in posizioni dispari */
  background-color: #ffffff; /* Colore verde chiaro */
}

.v-list-item:nth-child(even) {  /* Seleziona gli elementi in posizioni pari */
  background-color: #eaeaea; /* Colore blu chiaro */
}
</style>