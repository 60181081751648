<template>
  <v-card
      class="card-shadow border-radius-xl"
      min-height="250"
  >
    <div class="px-6 pt-4 pb-0 d-flex">
      <v-avatar
          :color="`bg-gradient-${data.iconColor} border-radius-xl mt-n8`"
          class="shadow"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
        >{{ data.icon }}
        </v-icon
        >
      </v-avatar>
      <p
          class="font-weight-bold text-h4 mb-0 ms-3"
      >
        {{ data.title }}
      </p>
    </div>
    <v-card-text>
      <hr>
      <v-list>
        <v-list-item v-for="(value, label) in data.items" :key="label">
          <span class="text-h6">{{ label }}</span><v-spacer /><span class="float-end text-h6 text-pink font-weight-bold">{{ value }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-if="data.infoFooter">
      <hr>
      <!-- <span class="font-weight-bold">{{ data.infoFooter.text }}</span> -->
<!--      {{ data.media.text }} - {{ data.media.value }}-->
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "StatsCardComposto",
  props: {
    data: Object
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<style scoped>
hr {
  color: #aaceec;
}
.list-item-border {
  border-bottom: 1px solid #e0e0e0; /* Scegli il colore del bordo che preferisci */
}
.v-list-item:nth-child(odd) {  /* Seleziona gli elementi in posizioni dispari */
  background-color: #ffffff; /* Colore verde chiaro */
}

.v-list-item:nth-child(even) {  /* Seleziona gli elementi in posizioni pari */
  background-color: #eaeaea; /* Colore blu chiaro */
}
</style>