<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="card-shadow border-radius-xl">
      <v-card-title
          class="card-border-bottom"
          style="background-color: #f8c93c">
            <span class="font-weight-bold text-white text-h5 mb-0">
              Cambia Prezzo di Listino
            </span>
      </v-card-title>
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <!-- form per validazione  -->
          <v-form ref="form" v-model="valid" lazy-validation>

            <v-card style="background-color: #ebebeb">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="priceListItem.wine_name"
                        label="Nome Vino"
                        color="pink"
                        hide-details="auto"
                        disabled
                        style="font-weight: bold"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="Quantità"
                        v-model="priceListItem.quantity"
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        color="pink"
                        flat
                        height="43"
                        type="number"
                        :rules="[rules.nonNumeriNegativi, rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="priceListItem.price_cost"
                        prefix="€"
                        label="Listino"
                        color="pink"
                        hide-details="auto"
                    ></v-text-field>
                    <v-text-field
                        prefix="€"
                        v-model="priceListItem.price_reduce"
                        label="Acquisto"
                        color="pink"
                        hide-details="auto"
                        @input="calcolaCampoPercentuale"
                    ></v-text-field>
                    <v-text-field
                        v-model="priceListItem.price_wine_glass"
                        prefix="€"
                        label="Prezzo al Calice Consigliato"
                        color="pink"
                        hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="priceListItem.percentage_value"
                        prefix="%"
                        label="Sconto"
                        color="pink"
                        hide-details="auto"
                        @input="calcolaCampoRiduzione"
                    ></v-text-field>
                    <v-text-field
                        v-model="priceListItem.price_sell"
                        prefix="€"
                        label="Prezzo Asporto Consigliato"
                        hide-details="auto"
                    ></v-text-field>
                    <div class="">
                      <v-btn
                          block
                          elevation="0"
                          :ripple="false"
                          large
                          class="block font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary mt-3 py-3 px-6"
                          @click="salvaPriceListItem">
                        Modifica Listino
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import listinoService from "@/services/listino.service";

export default {
  name: 'CambiaPrezzoListino',
  props: {
    idPriceListItem: [Number, String]
  },
  data() {
    return {
      dialog: false,
      listiniItems: [],
      filter: {},
      priceListItem: {},
      editedItem: {},
      valid: true,
      rules: {
        required: value => !!value || "Campo Obbligatorio.",
        nonNumeriNegativi: value => value > 0 || 'Inserisci un numero positivo e maggiore di zero',
      },
      valorizza: {
        costo: null,
        quantity: null,
        price: "",
        bottleCount: null,
        listinoItemsId: "",
      },
    }
  },
  mounted() {},
  watch: {
    idPriceListItem(newVal, oldVal) {
      if (newVal) {
        this.dialog = true;
        this.getPriceListItem(newVal)
      }
      console.log(oldVal)
    },
    "priceListItem.quantity"(val) {
      if (val !== null) {
        this.valorizza.quantity = val
        this.costo()
      }
    },
  },
  methods: {
    //get
    getBottleQty(quantity) {
      this.valorizza.quantity = quantity;
      this.costo()
    },
    async getPriceListItem(id) {
      const params = {
        price_list_item_id: id,
        store_id: this.$store.state.auth.user.store_id,
      }
        listinoService.retrieveByStoreAndItem(params).then(resp => {
          this.priceListItem = resp.data
        }).catch(error => {
          this.$dialog.message.error(`Error ${error}`, {
            type: "error",
            rounded: true,
            position: "top-right",
            color: "red",
            outlined: false,
            timeout: 1000,
          })
      }).catch().finally()
    },
    // calcoli
    calcolaCampoPercentuale() {
      if (this.priceListItem.price_cost !== this.priceListItem.price_reduce) {
        this.priceListItem.percentage_value = ((
            (this.priceListItem.price_cost - this.priceListItem.price_reduce) /
            this.priceListItem.price_cost
        ) * 100).toFixed(2);
      } else {
        this.priceListItem.percentage_value = 0;
      }
    },
    calcolaCampoRiduzione() {
      this.priceListItem.price_reduce =
          (this.priceListItem.price_cost -
              (this.priceListItem.price_cost * this.priceListItem.percentage_value) / 100).toFixed(2);
    },
    estraiDati(val) {
      let dati = this.listiniItems.filter(function (item) {
        return item.id === val;
      });
      this.priceListItem = dati[0]
      this.valorizza.price = dati[0].price_reduce;
      this.valorizza.listinoId = val.id;
      this.valorizza.boxquantity = null;
      this.costo();
    },
    costo() {
      let boxQuantity = this.valorizza.boxquantity;
      let quantity = this.valorizza.quantity;
      let price = this.valorizza.price;
      if (boxQuantity != null)
        return (this.valorizza.costo = price * quantity * boxQuantity);
      else return (this.valorizza.costo = price * quantity);
    },
    // save
    salvaPriceListItem() {
      if (this.priceListItem.quantity === undefined || this.priceListItem.quantity === "") {
        this.$dialog.message.error("Campo Quantità Vuoto", {
          type: "error",
          rounded: true,
          position: "top-right",
          color: "red accent-3",
          outlined: false,
          timeout: 2000,
        });
      } else {
        listinoService.editListinoItem(this.priceListItem.id, this.priceListItem).then(resp => {
          console.log(resp.data)
          this.$dialog.message.success("Listino modificato correttamente", {
            type: "success",
            rounded: true,
            position: "top-right",
            color: "green accent-5",
            outlined: false,
            timeout: 1000,
          });
          this.$emit('refresh')
          this.dialog = false
        })
      }
    },
  },
}
</script>