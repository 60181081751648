import { render, staticRenderFns } from "./StatsCardComposto.vue?vue&type=template&id=3311d6f5&scoped=true"
import script from "./StatsCardComposto.vue?vue&type=script&lang=js"
export * from "./StatsCardComposto.vue?vue&type=script&lang=js"
import style0 from "./StatsCardComposto.vue?vue&type=style&index=0&id=3311d6f5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3311d6f5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCardText,VIcon,VList,VListItem,VSpacer})
